import Link from "next/link";

const ActiveProducts = () => {
  return (
    <div
      className={
        "flex flex-col bg-secondary bg-opacity-5 border border-secondary-100/10 dark:border-0 dark:bg-gray-800 rounded shadow-lg p-6 max-h-64"
      }
    >
      <h4
        className={"mb-2 font-semibold text-lg text-gray-700 dark:text-white"}
      >
        Active Products
      </h4>
      <span className={"font-semibold text-3xl text-gray-700 dark:text-white"}>
        3
      </span>
      <p className={"mt-3 text-gray-500 text-sm"}>1 renewal pending</p>
      <Link href={"/products"} passHref>
        <a className={"text-primary mt-1 hover:text-primary-200"}>
          View my products
        </a>
      </Link>
    </div>
  );
};

export default ActiveProducts;
