import { useAuth } from "../lib/auth";
import Link from "next/link";
import CurrentBalance from "../components/dashboard/currentBalance";
import WelcomeGreeting from "../components/greeting";
import ActiveProducts from "../components/dashboard/activeProducts";
import CallUsage from "../components/dashboard/callUsage";
import SmsUsage from "../components/dashboard/smsUsage";
import AnnounceUsage from "../components/dashboard/announceUsage";
import DefaultLayout from "../components/layouts/default";
import { NextSeo } from "next-seo";

const Home = () => {
  const { user } = useAuth();

  return (
    <>
      <NextSeo title={"Dashboard"} />
      <div className="w-full px-20">
        <div className={"flex flex-row"}>
          <h2
            className={
              "mb-6 text-secondary-700 dark:text-white text-lg font-semibold"
            }
          >
            <WelcomeGreeting user={user} />
          </h2>
        </div>
        <div className={"grid grid-cols-1 md:grid-cols-4 gap-5 mb-8"}>
          {/* Display users currently active products */}
          <ActiveProducts />
          {/* Display call usage for account with month difference */}
          <CallUsage />
          {/* Display sms usage for account with month difference */}
          <SmsUsage />
          {/* Display announce credit usage for account with month difference */}
          <AnnounceUsage />
          {/* The users balance due, with little visual reward for a zero balance */}
          <CurrentBalance />
          {/*<div*/}
          {/*  className={*/}
          {/*    "flex flex-col mr-3 rounded shadow-lg p-6 h-fit bg-secondary/10 dark:border-0 dark:bg-gray-800"*/}
          {/*  }*/}
          {/*>*/}
          {/*  <div className="grid grid-cols-3 gap-10 mt-auto">*/}
          {/*    <Link href={"/products"} passHref>*/}
          {/*      <a*/}
          {/*        className={*/}
          {/*          "flex flex-col items-center justify-center border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white rounded py-5"*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <i className={"fa-light fa-box-check text-4xl mb-3"} />*/}
          {/*        Buy new Product*/}
          {/*      </a>*/}
          {/*    </Link>*/}
          {/*    <Link href={"/products"} passHref>*/}
          {/*      <a*/}
          {/*        className={*/}
          {/*          "flex flex-col items-center justify-center border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white rounded py-5"*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <i className={"fa-light fa-business-time text-4xl mb-3"} />*/}
          {/*        Pending Orders*/}
          {/*      </a>*/}
          {/*    </Link>*/}
          {/*    <Link href={"/products"} passHref>*/}
          {/*      <a*/}
          {/*        className={*/}
          {/*          "flex flex-col items-center justify-center border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white rounded py-5"*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <i className={"fa-light fa-coins text-4xl mb-3"} />*/}
          {/*        Add Credit*/}
          {/*      </a>*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className={"flex flex-col mr-3"}>*/}
          {/*  <div className={"grid grid-cols-1 md:grid-cols-2 gap-8 mb-8"}>*/}
          {/*    /!* Display users currently active products *!/*/}
          {/*    <ActiveProducts />*/}
          {/*    /!* Display call usage for account with month difference *!/*/}
          {/*    <CallUsage />*/}
          {/*    /!* Display sms usage for account with month difference *!/*/}
          {/*    <SmsUsage />*/}
          {/*    /!* Display announce credit usage for account with month difference *!/*/}
          {/*    <AnnounceUsage />*/}
          {/*    /!* The users balance due, with little visual reward for a zero balance *!/*/}
          {/*    <CurrentBalance />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

Home.getLayout = function getLayout(page) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default Home;
