import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Link from "next/link";

const periods = [
  "Today",
  "Yesterday",
  "Last 7 Days",
  "Last 14 Days",
  "Last 28 Days",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SmsUsage = () => {
  const [selected, setSelected] = useState(periods[0]);
  return (
    <div className={"flex flex-col bg-gray-800 rounded shadow-lg p-6 max-h-64"}>
      <div className={"flex flex-row items-center justify-between"}>
        <h4 className={"font-semibold text-lg text-gray-700 dark:text-white"}>
          SMS/MMS
        </h4>
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="flex flex-row items-center rounded shadow-sm px-3 py-2 bg-gray-700 text-sm font-medium text-gray-300 hover:bg-gray-600 z-10">
              <span>{selected}</span>
              <i className={"fas fa-caret-down ml-2"} />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute overflow-hidden right-0 mt-2 w-44 rounded shadow-lg bg-gray-700 z-20">
              <div>
                {periods.map((period) => (
                  <Menu.Item key={period}>
                    {({ active }) => (
                      <button
                        onClick={() => setSelected(period)}
                        className={classNames(
                          selected === period
                            ? "text-gray-200"
                            : "text-gray-400",
                          "flex flex-row w-full items-center justify-between px-4 py-2 text-sm hover:bg-gray-800"
                        )}
                      >
                        {period}
                        {selected === period ? (
                          <i className={"fas fa-check text-primary -mb-1"} />
                        ) : null}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <h4
        className={"font-semibold text-3xl mt-3 text-gray-700 dark:text-white"}
      >
        246
      </h4>
      <p className={"text-sm text-gray-500"}>Combined usage</p>
      <Link href={"/payments/credit"} passHref>
        <a className={"text-primary mt-2 hover:text-primary-200"}>Add Credit</a>
      </Link>
    </div>
  );
};

export default SmsUsage;
