import Reward from "react-rewards";
import { useRef } from "react";
import Link from "next/link";

const CurrentBalance = () => {
  let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const reward = useRef(null);
  const balance = 0;
  return (
    <div className={"flex flex-col bg-gray-800 rounded shadow-lg p-6 max-h-64"}>
      <h4
        className={"mb-2 font-semibold text-lg text-gray-700 dark:text-white"}
      >
        Outstanding Balance
      </h4>
      {balance === 0 ? (
        <Reward
          ref={(ref) => {
            reward.current = ref;
          }}
          type="confetti"
        >
          <span
            className={"font-semibold text-3xl text-gray-700 dark:text-white"}
            onMouseEnter={() => reward.current?.rewardMe()}
          >
            {dollarUS.format(balance)}
          </span>
        </Reward>
      ) : (
        <span
          className={"font-semibold text-3xl text-gray-700 dark:text-white"}
        >
          {dollarUS.format(balance)}
        </span>
      )}
      <Link href={"/statements"} passHref>
        <a className={"text-primary mt-3 hover:text-primary-200"}>
          View Statements
        </a>
      </Link>
    </div>
  );
};

export default CurrentBalance;
